//
// user.scss
//

/*********************************/
/*         Authentications       */
/*===============================*/

.login-page {
    font-size: 15px;
    .forgot-pass {
        z-index: 99;
        position: relative;
        font-size: 13px;
    }
} 

.cover-user {
    .container-fluid{
        max-width: 100%;
        .padding-less{

            @media (max-width: 768px) {
                position: relative !important;
                height: 400px !important;
            }

            &.img {
                background: scroll center no-repeat;
                background-size: cover;
                position: absolute;
                height: 100%;
            }
        }
    }    
    .cover-user-img {
        height: 100vh;

        @media (min-width: 1025px) {
            padding: 0px 20%;
        }
        
        @media (max-width: 768px) {
            align-items: start !important;
            height: auto !important;
            padding: 30px;
        }
    }

    
    @media (max-width: 768px) {
        .cover-user-content {
            text-align: left !important;
        }    
        .cover-my-30 {
            margin: 30px 0;
        }
    }
}

.form-signin {
    width: 100%;
    max-width: 400px;
    margin: auto;
}


//account-profile.html(Pages > Account)
.bg-profile {
    padding: 100px 0;
    @include home-common();
    .public-profile {
        top: 175px;
    }
}

//Chat
.chat {
    .chat-list {
        &:hover,
        &.active,
        &:focus {
            background: $light !important;
            box-shadow: $shadow;
        }
    }
    
    .chat-list,
    .chat-type {
        .on-off {
            position: absolute;
            left: -1px;
            top: -1px;
            font-size: 12px !important;
        }
    }
    
    &.chat-person {
        .on-off {
            font-size: 10px;
        }
        .chat-msg {
            margin-left: 8px;
            .msg {
                border: 1px solid $gray-200;
            }
            .msg-time {
                font-size: 12px !important;
            }
        }
        .chat-right {
            justify-content: flex-end;
            text-align: right;
            .chat-user-image {
                order: 2;
                .on-off {
                    right: -1px;
                }
            }
            .chat-msg {
                margin-right: 8px;
                margin-left: 0;
                .msg {
                    display: inline-block;
                }
                .msg-time {
                    display: block;
                }
            }
        }
    }
}

// _smiple-bar.scss
.simplebar-track {
    &.simplebar-vertical {
        width: 8px !important;
        .simplebar-scrollbar {
            &:before {
                width: 4px !important;
                background: $gray-500 !important;
            }
        }
    }
}