@import "pedigree";

a{
    color: $primary;
}
a:hover {
    color: rgba($primary, 0.6);
}

.container {
    @media (min-width: 1400px) {
        max-width: 1400px !important;
    }
}

body:not(.profile-page) .section{
    padding-top: 25px;
}

.text-black {
    color: $black
}

.bg-warning, .btn-warning, .alert-warning {
    color: $dark !important;
}
.bg-soft-warning{
    color: #FFA900 !important;
}

.font-sans {
    font-family: $font-family-secondary,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}
.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.bg-half-50 {
    padding: 50px 0;
    @include home-common();
}

.btn-group {
    .dropdown-menu {
        &:before {
            display: none;
        }
    }
}

h3.popover-header{ font-size: 1rem !important; }

.offcanvas.offcanvas-end{
    width: 420px;
}

#showPaymentModal #card-errors{ margin-bottom: 1rem; color: $danger !important; }
.back-to-top{ bottom: 80px; }
.helpline-number {
    position: fixed;
    z-index: 99;
    bottom: 10px;
    right: 30px;
    font-size: 14px;
    font-weight: bold;
    //transition: all 0.5s ease;
    /*height: 36px;
    width: 36px;*/
    /*line-height: 34px;
    border-radius: 6px;
    text-align: center;
    background: $primary;
    color: $white !important;
    .icons {
        transition: all 0.5s ease;
    }*/
    /*&:hover {
        transform: rotate(45deg);
        .icons {
            transform: rotate(-45deg) !important;
        }
    }*/
}
/*======================== Header ================================*/
.header-components-container, #topnav .header-components-container{
    @media (max-width: 320px) {
        flex-direction: column;
        align-items: end !important;
        .buy-button{
            line-height: 50px;
        }
    }
}

.btn-group.custom-dropdown {
    .dropdown-toggle, .notification-toggle {
        padding: 6px 9px;
    }
    .dropdown-menu {
        top: 12px !important;
        &:before {
            display: none;
        }
    }
    .header-horse-icon:after{ display:none; }
}

.horse-dropdown.custom-dropdown{
    .dropdown-menu{
        min-width: max-content;
        width: 100%;
    }
}

#topnav .logo-container .logo span{ font-family: 'Cormorant Garamond', cursive; color:#000; }
.double-underline:after, .double-underline:before{
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    background-color:black;
    display: block;
    height: 1px;
}
.double-underline:after {
    bottom: -3px;
}

.double-underline:before {
    bottom: 0px;
    height: 3px;
}

.custom-dropdown .dropdown-menu{ min-width: 16rem; }
.dropdown-primary .dropdown-menu .dropdown-item{ text-align: right; }
.dropdown-primary .dropdown-menu .dropdown-item:hover,
.dropdown-primary .dropdown-menu .dropdown-item.active,
.dropdown-primary .dropdown-menu .dropdown-item:active,
.dropdown-primary .dropdown-menu .dropdown-item.focus,
.dropdown-primary .dropdown-menu .dropdown-item:focus{ background-color: $primary;color: $white !important; }

.header-horse-icon{ border: 1px solid $dark; }
//card
/*.horses-list {
    .card {
        .card-body {
            padding: 0.5rem;
        }
    }
}*/

/*==================================== Page Content ==============================*/
.user-page-title-section, .horse-page-title-section{
    margin-top: 75px;
    @media (max-width: 320px) {
        margin-top: 95px;
    }
}

//pagination
.pagination-container {
    ul.pagination {
        margin-bottom: 0;
        justify-content: center;
    }
}

body.profile-page {
    .horse-profile-picture img, .user-profile-picture img{ max-height: 250px; }

    @media (min-width: 1400px) {
        #topnav:not(.nav-sticky) .logo span, #topnav:not(.nav-sticky) .navigation-menu > li > a, #topnav:not(.nav-sticky) .btn.btn-soft-primary {
            color: $white !important;
            padding-bottom: 0;
        }
        #topnav:not(.nav-sticky) .navigation-menu > li > a:hover {
            border-bottom: 1px solid $white;
        }
    }
}

/*.term-title{ padding-top: 74px; }*/
/*[id]::before*/.term-title::before {
    content: '';
    display: block;
    height:      75px;
    margin-top: -75px;
    visibility: hidden;
}

.carousel-cell {
    width: 100%;
    height: auto;
}

/*------------------------------ Popup ----------------------------*/
.ask-approval-mare-list-container{
    overflow-y: scroll;
    max-height: 405px;
    @media (min-width: 320px) and (max-width: 414px) {
        max-height: 320px;
    }
}

/*========================== Footer ===========================*/
.logo-footer span{ font-family: 'Cormorant Garamond', cursive; }

.offcanvas.show{ visibility: visible; }
