//
// variables.scss
//

/*********************************/
/*         Variables             */
/*===============================*/

//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@700&display=swap');
// Color
$white:                     #ffffff;

$primary:                   #BE5743;//#ce8147;//#1266F1;//#2f55d4; //2f55d4
$secondary:                 #6c757d;//#5cc8ff;//#5a6d90;
$success:                   #198754;//#2eca8b;
$warning:                   #ffc107;//#FFA900;//#f17425;
$info:                      #0dcaf0;//#17a2b8;
$danger:                    #dc3545;//#e43f52;//#e43f52;
$dark:                      #262626;//#3c4858;
$black:                     #161c2d;
$muted:                     #8492a6;
$light:                     #f8f9fc;

// Gray
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d;
$gray-700:                  #495057;

//Body Background
$shadow:                    0 0 3px rgba($dark,.15);
$shadow-md:                 0 5px 13px rgba($dark, .2);
$shadow-lg:                 0 10px 25px rgba($dark, 0.15);
$footer:                    lighten($black, 6%);

// Base font
$font-size-base:            16px;

$font-family-base:          'Fira Sans', sans-serif;
$font-family-secondary:     'Roboto', sans-serif;

//Color Picker/Switcher
$default:                   #ce8147;//#2f55d4;
$green:                     #6dc77a;
$red:                       #ff5b69;
$purple:                    #7952B3;
$skyblue:                   #32c2ec;
$skobleoff:                 #0f7173;
$cyan:                      #00c9a7;
$slateblue:                 #766df4;
$yellow:                    #f7961c;
$blue:                      #2f55d4;

// Overlay
$overlay:                   rgba($dark, 0.7);
$bg-overlay-white:          rgba($white, 0.5);
$bg-gradient-primary:       linear-gradient(to left, darken($purple, 10%) 0%, darken($primary, 10%) 100%);

$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "info": $info,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
    "blue": $blue,
    "footer": $footer
);

$heading-font-sizes: (
    "h1": 42px,
    "h2": 36px,
    "h3": 30px,
    "h4": 24px,
    "h5": 20px,
    "h6": 16px,
);

$display-font-sizes: (
    "display-1": 80px,
    "display-2": 72px,
    "display-3": 64px,
    "display-4": 56px,
    "display-5": 48px,
    "display-6": 40px,
)
